import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NFTCard from './NFTCard';
import NFTIcon from '../../media/Rectangle123.png';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import React from 'react';

const nfts = [
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
];

export interface NFTPayload {
  img: any;
}

const useStyles = makeStyles((theme: any) => ({
  header: {
    marginBottom: '10px !important',
    paddingInline: '15px',
    [theme.breakpoints.down(958)]: {
      textAlign: 'center',
    },
  },
  cardWrapper: {
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '20px',
    padding: '14px 9px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.main,
      border: `2px solid ${theme.palette.secondary.main}`,
    },
  },
}));

const assignItemsToColumns = (items: NFTPayload[], nCol: number) => {
  const columns: any = [];
  items.forEach((it: NFTPayload, index: number) => {
    if (index < nCol) {
      columns.push([it]);
    } else {
      columns[index % nCol].push(it);
    }
  });
  return columns;
};

const PreviewCollection: React.FC<{ name: string }> = ({ name }) => {
  const classes = useStyles();
  const theme = useTheme();
  const is3 = useMediaQuery(theme.breakpoints.up(600));
  const is4 = useMediaQuery(theme.breakpoints.up(750));
  const is5 = useMediaQuery(theme.breakpoints.up(900));
  const nCols = is5 ? 5 : is4 ? 4 : is3 ? 3 : 2;
  const cols = assignItemsToColumns(nfts, nCols);
  return (
    <>
      <Typography variant="h4" className={classes.header}>
        {name}
      </Typography>
      <Box display="flex" mb="40px" className={classes.cardWrapper}>
        {cols.map((col: NFTPayload[], idx: number) => (
          <Box key={idx} style={{ width: `${100 / nCols}%` }}>
            {col.map((nft: NFTPayload, index: number) => (
              <NFTCard nft={nft} key={index} />
            ))}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default PreviewCollection;
