import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, Box, Snackbar } from '@mui/material';
import { useEthers } from '@usedapp/core';

import ConnectButton from './ConnectButton';
import ToggleDarkModeButton from './ToggleDarkModeButton';
import HomeHeader from './HomeHeader';
import { sort0xAddress } from 'utils/helper';

const Header: React.FC = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const { activateBrowserWallet, account } = useEthers();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  if (isHomePage) {
    return (
      <HomeHeader
        account={account}
        activateBrowserWallet={activateBrowserWallet}
      />
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        padding: '20px',
        justifyContent: 'space-between',
      }}
    >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          variant="filled"
          severity="success"
        >{`thank you ${account}`}</Alert>
      </Snackbar>
      <Box>BROWSERS</Box>

      <Box sx={{ display: 'flex', gap: '20px' }}>
        <ToggleDarkModeButton />
        {account && sort0xAddress(account)}
        {!account && <ConnectButton onClick={activateBrowserWallet} />}
      </Box>
    </Box>
  );
};

export default Header;
