import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import React from 'react';
import { NFTPayload } from 'interfaces';
import NFTCard from 'components/NFTCard';
import { RegularImageContainerView } from 'ENUMS';
import { assignItemsToColumns } from 'utils/regularScreenHelper';

interface RegularCollectionContainerProps {
  activeView: RegularImageContainerView;
  collection: Array<NFTPayload>;
}

const RegularCollectionContainer: React.FC<RegularCollectionContainerProps> = ({
  activeView,
  collection,
}: RegularCollectionContainerProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const is3 = useMediaQuery(theme.breakpoints.down(900));
  const is4 = useMediaQuery(theme.breakpoints.up(900));
  const nCols =
    activeView === RegularImageContainerView.TWO ? 2 : is4 ? 4 : is3 ? 3 : 2;
  const cols = assignItemsToColumns(collection, nCols);
  return (
    <Box display="flex" mb="40px" className={classes.root}>
      {cols.map((col: NFTPayload[], idx: number) => (
        <Box key={idx} style={{ width: `${100 / nCols}%` }}>
          {col.map((nft: NFTPayload, index: number) => (
            <NFTCard nft={nft} key={index} />
          ))}
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2, 1),
  },
}));

export default RegularCollectionContainer;
