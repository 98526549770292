import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CollectionCard from './CollectionCard';
import CardIcon from '../../media/image21.png';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import React from 'react';

const collections = [
  { name: 'Collection 1', img: CardIcon },
  { name: 'Collection 2', img: CardIcon },
  { name: 'Collection 3', img: CardIcon },
];

export interface CollectionPayload {
  name: String;
  img: any;
}

const useStyles = makeStyles((theme: any) => ({
  header: {
    marginBottom: '10px !important',
    paddingInline: '15px',
    [theme.breakpoints.down(958)]: {
      textAlign: 'center',
    },
  },
  cardWrapper: {},
}));

const assignItemsToColumns = (items: CollectionPayload[], nCol: number) => {
  const columns: any = [];
  items.forEach((it: CollectionPayload, index: number) => {
    if (index < nCol) {
      columns.push([it]);
    } else {
      columns[index % nCol].push(it);
    }
  });
  return columns;
};

const FeatureCollections: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const hasTwoCol = useMediaQuery(theme.breakpoints.up(500));
  const hasThreeCol = useMediaQuery(theme.breakpoints.up(800));
  const nCols = hasThreeCol ? 3 : hasTwoCol ? 2 : 1;
  const cols = assignItemsToColumns(collections, nCols);
  return (
    <>
      <Typography variant="h1" className={classes.header}>
        Featured Collections
      </Typography>
      <Box display="flex" mb="40px">
        {cols.map((col: CollectionPayload[], idx: number) => (
          <Box key={idx} style={{ width: `${100 / nCols}%` }}>
            {col.map((collection: CollectionPayload, index: number) => (
              <CollectionCard collection={collection} key={index} />
            ))}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default FeatureCollections;
