import { Container } from '@mui/material';
import FeatureCollections from 'components/FeatureCollections';
import Footer from 'components/Footer';
import PreviewCollection from 'components/PreviewCollection.tsx';

const Home = () => {
  return (
    <>
      <Container sx={{ maxWidth: '996px !important' }}>
        <FeatureCollections />
        <PreviewCollection name="Collection 2" />
        <PreviewCollection name="Collection 3" />
        <Footer />
      </Container>
    </>
  );
};

export default Home;
