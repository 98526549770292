import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import RegularNavBarItem from './Atoms/RegularNavBarItem';
interface RegularNavBarProps {
  active: string;
  navigation: Array<any>;
  // eslint-disable-next-line no-unused-vars
  onItemClick(e: React.MouseEvent<HTMLDivElement>, item: string): void;
}

const RegularNavBar: React.FC<RegularNavBarProps> = ({
  navigation,
  onItemClick,
  active,
}: RegularNavBarProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {navigation.map((item, index) => (
        <RegularNavBarItem
          key={index}
          isActive={active === item}
          onClick={e => onItemClick(e, item)}
        >
          {item}
        </RegularNavBarItem>
      ))}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export default RegularNavBar;
