import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { NFTPayload } from '.';
import React from 'react';

const useStyles = makeStyles(() => ({
  collectionCard: {
    width: '100%',
    borderRadius: '8px',
    padding: '8px 8px',
    display: 'inline-flex',
    overflow: 'hidden',
    cursor: 'pointer',
    '& img': {
      width: '100%',
    },
  },
}));

const NFTCard: React.FC<{ nft: NFTPayload }> = ({ nft }) => {
  const classes = useStyles();
  return (
    <Box sx={{ boxSizing: 'border-box', width: '100%' }}>
      <Box className={classes.collectionCard}>
        <img src={nft.img} />
      </Box>
    </Box>
  );
};

export default NFTCard;
