import { Button } from '@mui/material';
import React from 'react';

const ConnectButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button sx={{ fontWeight: 700 }} onClick={onClick} variant="outlined">
    Connect
  </Button>
);

export default ConnectButton;
