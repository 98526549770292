import React from 'react';
import { ReactComponent as LogoWhite } from '../media/logo_white.svg';
import { ReactComponent as LogoBlack } from '../media/logo_black.svg';
import { ReactComponent as WordMarkWhite } from '../media/wordmark_white.svg';
import { ReactComponent as WordMarkBlack } from '../media/wordmark_black.svg';

export const IconLogo: React.FC<{
  isDarkMode: boolean;
  style?: React.CSSProperties;
  className?: string;
}> = ({ isDarkMode, style, className }) => (
  <>{isDarkMode ? <LogoWhite style={style} className={className} /> : <LogoBlack style={style} className={className} />}</>
);

export const BrowsersLogo: React.FC<{
  isDarkMode: boolean;
  style?: React.CSSProperties;
}> = ({ isDarkMode, style }) => (
  <>
    {isDarkMode ? (
      <WordMarkWhite style={style} />
    ) : (
      <WordMarkBlack style={style} />
    )}
  </>
);
