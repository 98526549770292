import { Theme, useMediaQuery } from '@mui/material';

export const isDarkMode = () => {
  if(localStorage.getItem('muiMode'))
    return localStorage.getItem('muiMode') !== 'light'
  return true
};  

export const sort0xAddress = (address: string) =>
  `${address.slice(0, 6)}...${address.slice(
    address.length - 1,
    address.length
  )}`;

export const isWidthLessThan = (width: number, theme: Theme) =>
  useMediaQuery(theme.breakpoints.down(width));
