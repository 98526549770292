/* eslint-disable no-unused-vars */

export enum ActiveTheme {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export enum RegularImageContainerView {
  TWO,
  FOUR,
}
