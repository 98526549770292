import React from 'react';
import { Box, useTheme } from '@mui/material';
import ToggleDarkModeButton from './ToggleDarkModeButton';
import ConnectButton from './ConnectButton';
import { BrowsersLogo, IconLogo } from './Logos';
import { isDarkMode, isWidthLessThan, sort0xAddress } from 'utils/helper';

const HomeHeader: React.FC<{
  account: string | null | undefined;
  activateBrowserWallet: () => void;
}> = ({ account, activateBrowserWallet }) => {
  const theme = useTheme();
  const isSmall = isWidthLessThan(740, theme);
  const isMedium = isWidthLessThan(900, theme);
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: isMedium ? 'left' : 'center',
        padding: isSmall ? '35px 24px' : '35px 54px',
        marginBottom: '50px',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', gap: '30px' }}>
        <IconLogo
          isDarkMode={isDarkMode()}
          style={{ width: '93px', height: '38px' }}
        />
        {!isSmall && (
          <BrowsersLogo
            isDarkMode={isDarkMode()}
            style={{ width: '311px', height: '39px' }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          position: 'absolute',
          top: '20px',
          right: '54px',
          alignItems: 'center',
        }}
      >
        <ToggleDarkModeButton />

        {account && (
          <Box sx={{ fontWeight: 700 }}>{sort0xAddress(account)}</Box>
        )}

        {!account && <ConnectButton onClick={activateBrowserWallet} />}
      </Box>
    </Box>
  );
};

export default HomeHeader;
