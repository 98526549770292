/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NFTSearchField from './NFTSearchField';
import ToggleViewButton from './ToggleViewButton';
import CachedSharpIcon from '@mui/icons-material/CachedSharp';
import { grey } from '@mui/material/colors';
import { RegularImageContainerView } from 'ENUMS';
interface RegularToolBarProps {
  onViewToggleChange(newValue: RegularImageContainerView): void;
  activeView: RegularImageContainerView;
}

const RegularToolBar: React.FC<RegularToolBarProps> = ({
  onViewToggleChange,
  activeView,
}: RegularToolBarProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={5}
    >
      <Grid item className={classes.toolbarItem}>
        filtered Results (100+)
      </Grid>
      <Grid item className={classes.toolbarItem}>
        only show liked
      </Grid>
      <ToggleViewButton
        onViewChange={onViewToggleChange}
        activeView={activeView}
      />
      <Grid item className={classes.toolbarItem}>
        sort by
      </Grid>
      <NFTSearchField onChange={() => undefined} />
      <Grid item className={classes.toolbarItem}>
        <IconButton className={classes.refreshButton} disableRipple>
          <CachedSharpIcon />
        </IconButton>
        <Grid item className={classes.refreshButtonHelperText}>
          139 results
          <br />
          less than a minute ago
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  toolbarItem: {
    textTransform: 'capitalize',
    display: 'flex',
    gap: theme.spacing(1),
    fontWeight: 500,
  },
  refreshButton: {
    borderRadius: `${theme.spacing(0.5)} !important`,
    border: `1px solid ${grey[800]} !important`,
    padding: `${theme.spacing(0.6)} !important`,
  },
  refreshButtonHelperText: {
    fontSize: '0.8rem',
  },
}));

export default RegularToolBar;
