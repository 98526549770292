import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Link, Theme } from '@mui/material';
import { BrowsersLogo } from './Logos';
import { isDarkMode } from 'utils/helper';

const useStyle = makeStyles((theme: Theme) => {
  return {
    linksWrapper: {
      gap: theme.spacing(2.5),
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& a': {
        paddingInline: theme.spacing(2.5),
        textDecoration: 'none',
      },
    },
  };
});

const Footer: React.FC = () => {
  const classes = useStyle();

  return (
    <Box
      display="flex"
      justifyContent="center"
      mt={20}
      flexWrap="wrap"
      pb="20px"
    >
      <Box mx="40px" pb="20px">
        <BrowsersLogo isDarkMode={isDarkMode()} style={{ width: '218px' }} />
      </Box>
      <Box className={classes.linksWrapper}>
        <Link href="#">Etherscan</Link>
        <Link href="https://twitter.com/Regulars_NFT">Twitter</Link>
        <Link href="https://discord.com/invite/regulars-nft">Discord</Link>
        <Link sx={{ whiteSpace: 'nowrap' }} href="https://mustard-labs.io/">
          Mustard Labs
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
