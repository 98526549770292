import { Button, useTheme } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import React, { useContext } from 'react';
import { ColorModeContext } from '../context/ColorModeContext';

const ToggleDarkModeButton: React.FC = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const handleToggleMuiMode = () => {
    localStorage.setItem(
      'muiMode',
      theme.palette.mode === 'dark' ? 'light' : 'dark'
    );
    colorMode.toggleColorMode();
  };
  return (
    <Button onClick={handleToggleMuiMode}>
      {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
    </Button>
  );
};

export default ToggleDarkModeButton;
