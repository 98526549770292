import React from 'react';
import CssBaseLine from '@mui/material/CssBaseline';
import { DAppProvider } from '@usedapp/core';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import Home from './routes/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Settings from './routes/Settings';
import TopBanner from './components/TopBanner';
import config from './web3/config';
import Header from './components/Header';
import getDesignTokens from './theme/theme';
import { ColorModeContext } from './context/ColorModeContext';
import Regulars from 'routes/Regulars';

const App = () => {
  const storedMode: string = localStorage.getItem('muiMode') || 'dark';
  const [mode, setMode] = React.useState<'light' | 'dark'>(
    storedMode === 'light' ? 'light' : 'dark'
  );
  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseLine />
        <DAppProvider config={config}>
          <BrowserRouter>
            <TopBanner />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/test" element={<Settings />} />
              <Route path="/regulars" element={<Regulars />} />
            </Routes>
          </BrowserRouter>
        </DAppProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
