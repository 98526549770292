import { Box, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import DiscordIcon from './DiscordIcon';

const RegularTitleSection: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.pageTitleSection}>
      <Typography
        className={classes.pageTitle}
        sx={{
          fontSize: '3.875rem',
          fontWeight: 600,
        }}
        component="h2"
      >
        Regulars
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Typography
          sx={{
            fontSize: '0.75rem',
            fontWeight: 600,
          }}
        >
          10,000 NFTS
        </Typography>
        <Typography
          sx={{
            fontSize: '0.75rem',
            fontWeight: 600,
          }}
        >
          102 Offers Made
        </Typography>
        <Typography
          sx={{
            fontSize: '0.6rem',
            fontWeight: 600,
          }}
        >
          Floor: .05 E
        </Typography>
        <TwitterIcon sx={{fontSize: "16px"}}/>
        <InstagramIcon sx={{fontSize: "16px"}} />
        <DiscordIcon style={{fontSize: "16px", color: "#fff"}} />
      </Grid>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  pageTitleSection: {
    width: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  pageTitle: {
    fontSize: '4rem',
    fontWeight: 600,
  },
  subSection: {
    display: 'flex',
    gap: 5,
    marginTop: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
export default RegularTitleSection;
