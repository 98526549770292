import { Box } from '@mui/material';
import React from 'react';

const TopBanner: React.FC = () => {
  return (
    <Box
      sx={{
        padding: '10px',
        background: '#641c75',
        display: 'flex',
        color: 'white',
        justifyContent: 'center',
      }}
    >
      test
    </Box>
  );
};

export default TopBanner;
