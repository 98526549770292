import { NFTPayload } from "interfaces";

const assignItemsToColumns = (items: NFTPayload[], nCol: number) => {
    const columns: any = [];
    items.forEach((it: NFTPayload, index: number) => {
      if (index < nCol) {
        columns.push([it]);
      } else {
        columns[index % nCol].push(it);
      }
    });
    return columns;
  };

  export {
      assignItemsToColumns
  }