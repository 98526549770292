import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import React from 'react';
import { NFTPayload } from 'interfaces';

interface NFTCardProps {
  nft: NFTPayload;
}

const NFTCard: React.FC<NFTCardProps> = ({ nft }: NFTCardProps) => {
  const classes = useStyles();
  return (
    <Box sx={{ boxSizing: 'border-box', width: '100%' }}>
      <Box className={classes.collectionCard}>
        <img src={nft.img} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  collectionCard: {
    width: '100%',
    borderRadius: '8px',
    padding: '8px 8px',
    display: 'inline-flex',
    overflow: 'hidden',
    cursor: 'pointer',
    '& img': {
      width: '100%',
    },
  },
}));

export default NFTCard;
