import { Container } from '@mui/material';
import React from 'react';
import RegularNavBar from 'components/RegularPage/RegularNavBar';
import RegularTitleSection from 'components/RegularPage/RegularTitleSection';
import RegularToolBar from 'components/RegularPage/RegularToolBar';
import RegularCollection from 'components/RegularPage/RegularCollection';
import NFTIcon from 'media/Rectangle123.png';
import { RegularImageContainerView } from 'ENUMS';

const Regulars: React.FC = () => {
  const [activeNavItem, setActiveNavItem] = React.useState('browse');
  const [view, setActiveView] = React.useState<RegularImageContainerView>(
    RegularImageContainerView.FOUR
  );
  const navigation = ['browse', 'my offers', 'execute', 'pools', 'activity'];
  const handleNavItemClick = (item: string) => {
    setActiveNavItem(item);
  };
  const handleUpdateView = (value: RegularImageContainerView) => {
    setActiveView(value)
  }
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <RegularTitleSection />
      <RegularNavBar
        active={activeNavItem}
        navigation={navigation}
        onItemClick={(e, item) => handleNavItemClick(item)}
      />
      <RegularToolBar onViewToggleChange={handleUpdateView} activeView={view} />
      <RegularCollection collection={nfts} activeView={view} />
    </Container>
  );
};

const nfts = [
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
  { img: NFTIcon },
];

export default Regulars;
