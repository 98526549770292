import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { CollectionPayload } from '.';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  collectionCard: {
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '13px',
    padding: '18px 21px',
    display: 'inline-block',
    cursor: 'pointer',
    '& img': {
      width: '100%',
    },
    '&:hover': {
      background: theme.palette.primary.main,
      border: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
    },
  },
}));

const CollectionCard: React.FC<{ collection: CollectionPayload }> = ({
  collection,
}) => {
  const classes = useStyles();
  return (
    <Box sx={{ boxSizing: 'border-box', width: '100%', padding: '15px' }}>
      <Box className={classes.collectionCard}>
        <Typography variant="h4" sx={{ marginBottom: '18px' }}>
          {collection.name}
        </Typography>
        <img src={collection.img} />
      </Box>
    </Box>
  );
};

export default CollectionCard;
