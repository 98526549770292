/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey, red } from '@mui/material/colors';
import { Theme } from '@mui/material';

import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ViewComfySharpIcon from '@mui/icons-material/ViewComfySharp';
import clsx from 'clsx';
import { RegularImageContainerView } from 'ENUMS';
import { isDarkMode } from 'utils/helper';

interface ToggleViewProps {
  onViewChange(value: RegularImageContainerView): void;
  activeView: RegularImageContainerView;
}

const ToggleViewButton: React.FC<ToggleViewProps> = ({
  onViewChange,
  activeView,
}: ToggleViewProps) => {
  const classes = useStyles();
  const handleChangeView = (newView: RegularImageContainerView) => {
    onViewChange(newView);
  };
  return (
    <Grid
      className={clsx(classes.root, { [classes.lightMode]: !isDarkMode() })}
    >
      <Grid
        className={clsx({
          [classes.icon]: true,
          [classes.active]: activeView === RegularImageContainerView.FOUR,
        })}
        onClick={() => handleChangeView(RegularImageContainerView.FOUR)}
      >
        <ViewComfySharpIcon />
      </Grid>
      <Grid
        className={clsx({
          [classes.icon]: true,
          [classes.active]: activeView === RegularImageContainerView.TWO,
        })}
        onClick={() => handleChangeView(RegularImageContainerView.TWO)}
      >
        <GridViewRoundedIcon />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    background: grey[900],
  },
  icon: {
    padding: theme.spacing(0.4),
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    border: `1px solid ${red[600]}`,
    borderRadius: theme.spacing(0.5),
  },
  lightMode: {
    background: `${theme.palette.common.white} !important`,
  },
}));

export default ToggleViewButton;
