import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { isDarkMode } from 'utils/helper';

interface RegularNavBarItemProps {
  // eslint-disable-next-line no-unused-vars
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
  isActive: boolean;
}

const RegularNavBarItem: React.FC<
  React.PropsWithChildren<RegularNavBarItemProps>
> = ({
  onClick,
  children,
  isActive,
}: React.PropsWithChildren<RegularNavBarItemProps>) => {
  const classes = useStyles();
  return (
    <Box
      onClick={onClick}
      className={clsx(classes.root, {
        [classes.active]: isDarkMode() && isActive,
        [classes.LightMode]: !isDarkMode(),
        [classes.activeLightMode]: !isDarkMode() && isActive,
      })}
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: "wrap",
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2.125, 4.4),
    borderRadius: theme.spacing(4),
    minWidth: 120,
    fontSize: '1.1875rem',
    lineHeight: '1,4375rem',
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.common.white,
    textTransform: 'capitalize',
  },
  active: {
    color: theme.palette.common.black,
    background: theme.palette.common.white,
  },
  activeLightMode: {
    color: `${theme.palette.common.white} !important`,
    background: theme.palette.common.black,
  },
  LightMode: {
    color: theme.palette.common.black,
  },
}));

export default RegularNavBarItem;
