import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

interface NFTSearchFieldProps {
  // eslint-disable-next-line no-unused-vars
  onChange(value: string): void;
}

const NFTSearchField: React.FC<NFTSearchFieldProps> = ({
  onChange,
}: NFTSearchFieldProps) => {
  const classes = useStyles();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <Box className={classes.root}>
      <StyledTextField
        onChange={handleChange}
        InputProps={{
          endAdornment: <Search />,
        }}
        placeholder="Search NFTs by name or token ID"
        fullWidth
      />
    </Box>
  );
};

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1),
  },
}));

const useStyles = makeStyles(() => ({
  root: {
      minWidth: 350
  },
}));

export default NFTSearchField;
