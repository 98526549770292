import { Mainnet, Rinkeby } from '@usedapp/core';
import { getDefaultProvider } from 'ethers';

const infuraId = process.env.REACT_APP_INFURA_ID;

const config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: infuraId
      ? 'https://mainnet.infura.io/v3/39971fe2ad00481bb2b331d3fed8366d'
      : getDefaultProvider('mainnet'),
    [Rinkeby.chainId]: infuraId
      ? 'https://rinkeby.infura.io/v3/39971fe2ad00481bb2b331d3fed8366d'
      : getDefaultProvider('rinkeby'),
  },
};

export default config;
