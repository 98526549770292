const getDesignTokens = (mode: 'light' | 'dark') => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          background: {
            default: '#FFFFFF',
          },
          primary: {
            main: '#000000',
          },
          secondary: {
            main: '#FFFFFF',
          },
          success: {
            main: '#19B822',
          },
        }
      : {
          background: {
            default: '#000000',
          },
          primary: {
            main: '#FFFFFF',
          },
          secondary: {
            main: '#000000',
          },
          success: {
            main: '#19B822',
          },
        }),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          fontWeight: 700,
          borderRadius: '110px',
          padding: '21px',
        },
        outlined: {
          border: '2px solid',
          '&:hover': {
            border: '2px solid',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['Inter'].join(','),
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    h5: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    h4: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '21px',
    },
    h3: {
      fontSize: 21,
      fontWeight: 700,
      lineHeight: '25px',
    },
    h2: {
      fontSize: 24,
      lineHeight: '28px',
      fontWeight: 700,
    },
    h1: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '35px',
    },
    smallHeading: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
});

export default getDesignTokens;
